<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";
export default {
	components: {
		Offer
	},
	name: 'kancelarie',
	mixins: [handleWith],
}
</script>

<template>
	<Offer>
		<template #banner>
			<img src="./img/kanclearie.jpg" class="fixed-image" alt="banner">
		</template>
		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spisu usług</router-link>
		</template>
		<template #small-title>
					<h4>PRAWO PODATKOWE</h4>
				</template>

				<template #title>
					<h2>Obsługa Kancelarii Prawnych

					</h2>
				</template>

				<template #text>
					<p>Zapewniamy także kompleksowe wsparcie podatkowe dla kancelarii prawnych, które potrzebują wyspecjalizowanej pomocy w zakresie prawa podatkowego. Działamy jako partner merytoryczny dla innych prawników, dostarczając m.in. ekspertyzy podatkowe i reprezentację przed organami podatkowymi.

					</p>
				</template>
	</Offer>
</template>

<style scoped lang="scss">
.fixed-image {
	object-fit: cover;
}
</style>
